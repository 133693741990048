import { useMemo, useRef } from 'react'

/**
 * Calculate distinct values for each column in either buildings or spaces table
 * @param {Object} params
 * @param {Array} params.columns - Column definitions (buildingColumns or spaceColumns)
 * @param {Array} params.data - Processed data array with values
 * @param {boolean} params.isNested - Whether data has nested structure (true for spaces, false for buildings)
 */
export const useColumnStats = ({ columns, data, isNested = false }) => {
  // Keep track of all distinct values we've ever seen
  const allTimeDistinctValues = useRef({})
  
  return useMemo(() => {
    const stats = {}
    
    columns.forEach(column => {
      const key = column.field?.id || column.displayName
      const distinctValues = new Set()
      
      // Initialize if not exists
      if (!allTimeDistinctValues.current[key]) {
        allTimeDistinctValues.current[key] = new Set()
      }

      const processValue = (value) => {
        if (value != null) {
          const formattedValue = formatValue(value)
          distinctValues.add(formattedValue)
          allTimeDistinctValues.current[key].add(formattedValue)
        }
      }

      if (isNested) {
        data.forEach(building => {
          building.listings.forEach(listing => {
            const cellValue = listing.values[columns.indexOf(column)]?.value
            processValue(cellValue)
          })
        })
      } else {
        data.forEach(building => {
          const cellValue = building.values[columns.indexOf(column)]?.value
          processValue(cellValue)
        })
      }

      stats[key] = {
        distinctCount: distinctValues.size,
        filteredDistinctCount: distinctValues.size,
        // Use the superset for dropdown options
        fullDistinctValues: allTimeDistinctValues.current[key],
        fullDistinctCount: allTimeDistinctValues.current[key].size,
        fieldType: column.fieldDataType?.id,
        isReserved: column.reserved,
        displayName: column.displayName
      }
    })

    return stats
  }, [columns, data, isNested])
}

/**
 * Format cell value for consistent comparison
 */
const formatValue = (value) => {
  if (value === null || value === undefined) return ''
  
  // Handle address objects
  if (typeof value === 'object' && value.address) {
    return value.address
  }
  
  return String(value).trim().toLowerCase()
} 