import React, { useState } from 'react'
import { ApplyFiltersButton } from './search-controls'
import { useSearchQueryState } from '~/legacy/components/search-bar/search-context'

export const INPUT_STYLE = 'p-2 rounded border border-solid border-gray-300'

// const NumericOperatorSelect = ({ value, onChange }) => (
//   <select
//     value={value}
//     onChange={(e) => onChange(e.target.value)}
//     className={`shrink-0 w-16 ${INPUT_STYLE}`}
//   >
//     <option value="=">=</option>
//     <option value=">">&gt;</option>
//     <option value="<">&lt;</option>
//     <option value=">=">&gt;=</option>
//     <option value="<=">&lt;=</option>
//     <option value="range">r</option>
//   </select>
// )

export const NumericFieldInput = ({ value = '', onChange }) => {
  const defaultOperator = { operator: 'range', value: '', maxValue: '' }
  // Parse the existing value string into operator and values
  const parseValue = (str) => {
    if (!str) return defaultOperator;
    if (str.includes('-')) {
      const [min, max] = str.split('-')
      return { operator: 'range', value: min, maxValue: max }
    }
    const matches = str.match(/^([<>]=?|=)?(.+)$/)
    if (matches) {
      return { operator: matches[1] || '=', value: matches[2], maxValue: '' }
    }
    return defaultOperator;
  }

  const { /* operator: _operator, */ value: minValue, maxValue } = parseValue(value)

  const handleChange = (newOperator, newValue, newMaxValue = '') => {
    if (newOperator === 'range') {
      onChange(`${newValue}-${newMaxValue}`)
    } else {
      onChange(`${newOperator}${newValue}`)
    }
  }

  return (
    <div className="flex gap-2 min-w-0 flex-1">
      {/* Temporarily hide the type selector
      <NumericOperatorSelect
        value={operator}
        onChange={(newOperator) =>
          handleChange(newOperator, minValue, maxValue)
        }
      /> */}
      <div className="flex-1 flex gap-2 items-center min-w-0">
        <input
          type="number"
          value={minValue}
          onChange={(e) => handleChange('range', e.target.value, maxValue)}
          placeholder="Min"
          className={`min-w-0 flex-1 ${INPUT_STYLE}`}
        />
        <span className="whitespace-nowrap px-1">to</span>
        <input
          type="number"
          value={maxValue}
          onChange={(e) => handleChange('range', minValue, e.target.value)}
          placeholder="Max"
          className={`min-w-0 flex-1 ${INPUT_STYLE}`}
        />
      </div>
    </div>
  )
}

const DateInput = ({ value = '', onChange }) => {
  const [date, setDate] = useState(value)

  return (
    <input
      type="date"
      value={date}
      onChange={(e) => {
        setDate(e.target.value)
        onChange(e.target.value)
      }}
      className={`min-w-0 w-full ${INPUT_STYLE}`}
    />
  );
};

export const DateRangeInput = ({ value = '', onChange }) => {
  // Parse the input value which could be either a string (old format) or an object (new format)
  const parseValue = (input) => {
    if (!input) return { startDate: '', endDate: '' }
    
    // If it's already an object with the new format
    if (typeof input === 'object' && input.value) {
      return {
        startDate: input.value.start || '',
        endDate: input.value.end || ''
      }
    }
    
    // Handle legacy string format (timestamp ranges)
    if (input.includes('-')) {
      const [start, end] = input.split('-')
      return {
        startDate: start ? new Date(parseInt(start, 10) * 1000).toISOString().split('T')[0] : '',
        endDate: end ? new Date(parseInt(end, 10) * 1000).toISOString().split('T')[0] : ''
      }
    }
    
    const matches = input.match(/^([<>]=?|=)?(\d+)$/)
    if (matches) {
      const timestamp = parseInt(matches[2], 10)
      const operator = matches[1] || '='
      const date = new Date(timestamp * 1000).toISOString().split('T')[0]
      
      switch (operator) {
        case '>=':
          return { startDate: date, endDate: '' }
        case '<=':
          return { startDate: '', endDate: date }
        default:
          return { startDate: date, endDate: date }
      }
    }
    
    return { startDate: '', endDate: '' }
  }

  const { startDate, endDate } = parseValue(value)

  const handleDateChange = (newStartDate, newEndDate) => {
    if (!newStartDate && !newEndDate) {
      onChange('')
      return
    }

    // Format in new API structure
    onChange({
      value: {
        start: newStartDate || null,
        end: newEndDate || null
      },
      data_type: 7 // FieldDataTypes.DATE
    })
  }

  return (
    <div className="flex gap-2 items-center min-w-0">
      <div className="flex-1 min-w-0">
        <DateInput 
          value={startDate}
          onChange={(newStartDate) => handleDateChange(newStartDate, endDate)}
        />
      </div>
      <span className="whitespace-nowrap px-1">to</span>
      <div className="flex-1 min-w-0">
        <DateInput 
          value={endDate}
          onChange={(newEndDate) => handleDateChange(startDate, newEndDate)}
        />
      </div>
    </div>
  )
}

export const CustomFieldInput = ({ field, value, onChange, columnStats }) => {
  const dataTypeToInputType = {
    1: 'text', // String
    3: 'number', // Number
    5: 'number', // SIZE_SQFT
    7: 'date', // Date
  }

  const inputType = dataTypeToInputType[field.data_type] || 'text'

  if (inputType === 'number') {
    return <NumericFieldInput value={value || ''} onChange={onChange} />
  }

  if (inputType === 'date') {
    return <DateRangeInput value={value || ''} onChange={onChange} />
  }

  if (inputType === 'text') {
    return <StringFieldInput 
      value={value || ''} 
      onChange={onChange}
      distinctValues={columnStats?.distinctValues}
      distinctCount={columnStats?.distinctCount}
    />
  }

  return (
    <input
      type={inputType}
      value={value || ''}
      onChange={(e) => onChange(e.target.value)}
      className={`flex-1 ${INPUT_STYLE}`}
    />
  )
}

const chooseElementByCardinality = (distinctCount) => {
  if (distinctCount <= 3) return 'dropdown-small'
  if (distinctCount <= 5) return 'checkboxes'
  if (distinctCount <= 50) return 'dropdown-large'
  return 'text'
}

const StringFieldInput = ({ value, onChange, distinctValues = new Set(), distinctCount = 0 }) => {
  const elementType = chooseElementByCardinality(distinctCount)
  
  switch (elementType) {
    case 'dropdown-small':
      return <SmallDropdown 
        value={value} 
        onChange={onChange} 
        options={Array.from(distinctValues)} 
      />
    case 'checkboxes':
      return <CheckboxGroup 
        value={value} 
        onChange={onChange} 
        options={Array.from(distinctValues)} 
      />
    case 'dropdown-large':
      return <LargeDropdown 
        value={value} 
        onChange={onChange} 
        options={Array.from(distinctValues)} 
      />
    default:
      return <StandardInput 
        value={value} 
        onChange={onChange} 
      />
  }
}

const SmallDropdown = ({ value, onChange, options }) => (
  <select
    value={typeof value === 'object' ? value.value : value}
    onChange={(e) => onChange({ value: e.target.value, exact: true })}
    className={`w-full ${INPUT_STYLE}`}
  >
    <option value="">- Any -</option>
    <option value="__blank__">- Blank -</option>
    {options.map(opt => (
      <option key={opt} value={opt}>{opt}</option>
    ))}
  </select>
)

const CheckboxGroup = ({ value, onChange, options }) => {
  // Handle both string and object values
  const rawValue = typeof value === 'object' ? value.value : value
  const selectedValues = new Set(rawValue ? rawValue.split(',') : [])
  
  const handleChange = (opt, checked) => {
    const newValues = new Set(selectedValues)
    if (checked) {
      newValues.add(opt)
    } else {
      newValues.delete(opt)
    }
    // Always use exact matching for checkboxes
    onChange({ 
      value: Array.from(newValues).join(','),
      exact: true 
    })
  }

  return (
    <div className="space-y-2">
      {options.map(opt => (
        <label key={opt} className="flex items-center gap-2">
          <input
            type="checkbox"
            checked={selectedValues.has(opt)}
            onChange={(e) => handleChange(opt, e.target.checked)}
          />
          <span>{opt}</span>
        </label>
      ))}
    </div>
  )
}

const LargeDropdown = ({ value, onChange, options }) => (
  <select
    value={typeof value === 'object' ? value.value : value}
    onChange={(e) => onChange({ value: e.target.value, exact: true })}
    className={`w-full ${INPUT_STYLE}`}
  >
    <option value="">- Any -</option>
    <option value="__blank__">- Blank -</option>
    {options.map(opt => (
      <option key={opt} value={opt}>{opt}</option>
    ))}
  </select>
)

export const StandardInput = ({
  type = 'text',
  value,
  onChange,
  placeholder,
  className,
  maxLength,
  ...props
}) => {
  // Handle both string and object values
  const inputValue = typeof value === 'object' ? value.value : value

  return (
    <input
      type={type}
      value={inputValue || ''}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      className={`w-full ${INPUT_STYLE} ${className || ''}`}
      maxLength={maxLength}
      {...props}
    />
  )
}

export const LatLngInput = ({ lat, lng, onLatChange, onLngChange }) => {
  return (
    <div className="flex gap-2">
      <div className="flex-1">
        <input
          type="number"
          value={lat || ''}
          onChange={(e) => onLatChange(e.target.value)}
          placeholder="Latitude"
          step="0.000001"
          className={INPUT_STYLE}
        />
      </div>
      <div className="flex-1">
        <input
          type="number"
          value={lng || ''}
          onChange={(e) => onLngChange(e.target.value)}
          placeholder="Longitude"
          step="0.000001"
          className={INPUT_STYLE}
        />
      </div>
    </div>
  )
}

export function FullAddressSearchElement() {
  const { filters, setFilters } = useSearchQueryState()

  return (
    <div className="flex gap-2">
      <StandardInput
        value={filters.fullAddressMatches}
        onChange={(value) => setFilters({ fullAddressMatches: value })}
        placeholder="Search address..."
      />
      <ApplyFiltersButton 
        isDirty
      />
    </div>
  )
} 