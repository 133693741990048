import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useEffect,
} from 'react'
import {
  StringParam,
  NumberParam,
  JsonParam,
  useQueryParams,
} from 'use-query-params'
import { isNil } from 'lodash'
import { useCollectionContext } from '~/legacy/pages/Surveys/Survey/bulk-edit-collection/collection-context'

const SearchContext = createContext()
const BULK_EDIT_URL_SCHEMA = {
  // Standard building filters
  address: StringParam,
  buildingName: StringParam,
  city: StringParam,
  state: StringParam,
  zipcode: StringParam,
  propertyType: StringParam,
  minSize: NumberParam,
  maxSize: NumberParam,
  // New location search parameters
  fullAddressMatches: StringParam,
  searchRadiusMiles: NumberParam,
  searchRadiusLat: NumberParam,
  searchRadiusLng: NumberParam,
  searchRadiusGeocodable: StringParam,
  // Survey filters
  createdAfter: StringParam,
  createdBefore: StringParam,
  // Sort params
  sortColumn: StringParam,
  sortOrder: StringParam,
  // Custom field filters
  customFieldFilters: JsonParam,
  // Space filters
  spaceName: StringParam,
}

const useUrlFilters = () => {
  const [urlFilters, setUrlFiltersRaw] = useQueryParams(
    BULK_EDIT_URL_SCHEMA,
    {
      replace: true,
      scroll: false,
    }
  )

  const setUrlFilters = useCallback(
    (newFilters) => {
      // Replace empty strings with null and serialize custom field filters
      const cleanedFilters = Object.entries(newFilters).reduce(
        (acc, [key, value]) => {
          if (value === '') {
            acc[key] = null
          } else if (key === 'customFieldFilters') {
            // Handle customFieldFilters specially
            acc[key] = value
          } else {
            // For individual custom field IDs, serialize if they're objects
            acc[key] = serializeFilterValue(value)
          }
          return acc
        },
        {}
      )

      setUrlFiltersRaw(cleanedFilters, 'replace')
    },
    [setUrlFiltersRaw]
  )

  const { sortColumn, sortOrder, customFieldFilters, ...filters } = urlFilters

  // Deserialize any custom field values that might be stringified objects
  const deserializedCustomFieldFilters = Object.fromEntries(
    Object.entries(customFieldFilters || {}).map(([key, value]) => [
      key,
      deserializeFilterValue(value)
    ])
  )

  return [
    {
      filters: filters || {},
      customFieldFilters: deserializedCustomFieldFilters,
      sortColumn: sortColumn || null,
      sortOrder: sortOrder || 'asc',
    },
    setUrlFilters,
  ]
}

const serializeFilterValue = (value) => {
  if (typeof value === 'object' && value !== null) {
    return JSON.stringify(value)
  }
  return value
}

const deserializeFilterValue = (value) => {
  try {
    return JSON.parse(value)
  } catch (e) {
    return value
  }
}

export const SearchProvider = ({ children }) => {
  const [urlFilters, setUrlFilters] = useUrlFilters()

  const [filters, setFilters] = useState(urlFilters.filters)
  const [customFieldFilters, setCustomFieldFilters] = useState(
    urlFilters.customFieldFilters
  )
  const [sortColumn, setSortColumn] = useState(urlFilters.sortColumn)
  const [sortOrder, setSortOrder] = useState(urlFilters.sortOrder)
  const [activeFilterCount, setActiveFilterCount] = useState(0)

  const { loadCollection } = useCollectionContext()

  const refresh = useCallback(
    (overrides = {}) => {
      const payload = {
        filters,
        customFieldFilters,
        sortColumn,
        sortOrder,
        ...overrides,
      }

      // Properly serialize custom field filters for the URL
      const urlParams = {
        ...filters,
        ...Object.entries(customFieldFilters).reduce((acc, [key, value]) => {
          acc[key] = serializeFilterValue(value)
          return acc
        }, {}),
        sortColumn,
        sortOrder,
        ...overrides,
      }

      setUrlFilters(urlParams)
      loadCollection(payload)
    },
    [loadCollection, filters, customFieldFilters, sortColumn, sortOrder]
  )

  useEffect(() => {
    loadCollection(urlFilters)
  }, [])

  useEffect(() => {
    const countActiveFilters = () => {
      const isValidValue = value => !isNil(value) && value !== '' && value !== false;
      
      const activeFilters = Object.entries(filters)
        .filter(([key]) => key in BULK_EDIT_URL_SCHEMA)
        .filter(([, value]) => isValidValue(value));

      const activeCustomFilters = Object.entries(customFieldFilters)
        .filter(([key]) => key in BULK_EDIT_URL_SCHEMA)
        .filter(([, value]) => isValidValue(value));

      setActiveFilterCount(activeFilters.length + activeCustomFilters.length)
    };

    countActiveFilters();
  }, [filters, customFieldFilters]);

  const clearFilters = useCallback(() => {
    setFilters({})
    setCustomFieldFilters({})
    setSortColumn(null)
    setSortOrder('asc')
    refresh()
    
    // Create an object with all schema keys set to null, except sort params
    const nullifiedFilters = Object.keys(BULK_EDIT_URL_SCHEMA)
      .filter(key => !['sortColumn', 'sortOrder'].includes(key))
      .reduce((acc, key) => {
        acc[key] = null
        return acc
      }, {})
    
    setUrlFilters(nullifiedFilters)
  }, [refresh])

  const handleCustomFieldFiltersUpdate = (newFilters) => {
    const serializedFilters = Object.fromEntries(
      Object.entries(newFilters).map(([key, value]) => [
        key,
        serializeFilterValue(value)
      ])
    )
    
    // Update local state first
    setCustomFieldFilters(newFilters)
    
    // Then update URL
    setUrlFilters(
      (prev) => ({
        ...prev,
        ...serializedFilters
      }),
      { replace: true }
    )
  }

  const value = {
    filters,
    setFilters,
    customFieldFilters,
    setCustomFieldFilters: handleCustomFieldFiltersUpdate,
    setSortColumn,
    sortColumn,
    setSortOrder,
    sortOrder,
    refresh,
    activeFilterCount,
    clearFilters,
  }

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  )
}

export const useSearchQueryState = () => {
  const context = useContext(SearchContext)
  if (context === undefined) {
    throw new Error('useSearchQueryState must be used within a SearchProvider')
  }
  return context
}
